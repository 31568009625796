import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AsignacionesComponent } from './components/asignaciones/asignaciones.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { EntregasComponent } from './components/entregas/entregas.component';
import { InventarioComponent } from './components/inventario/inventario/inventario.component';
import { LoginComponent } from './components/login/login.component';
import { PedidosComponent } from './components/pedidos/pedidos.component';
import { ReporteComponent } from './components/reporte/reporte.component';
import { TrackingComponent } from './components/tracking/tracking.component';
import { PedidoComponent } from './components/ubicaciones/pedido/pedido.component';

// Import Containers
import { DefaultLayoutComponent } from './containers';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path: 'home',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'maps',
        loadChildren: () => import('./components/google-maps/google-maps.module').then(m => m.GoogleMapsModule)
      },
      {
        path:'asignaciones',
        component:AsignacionesComponent
      },
      {
        path:'pedidos',
        component:PedidosComponent
      },
      {
        path:'empaque',
        component:EntregasComponent
      },
      {
        path:'entregas',
        component:TrackingComponent
      },
      {
        path:'clientes',
        component:ClientesComponent
      },
      {
        path:'reporte',
        component:ReporteComponent
      },
      {
        path:'inventario',
        component:InventarioComponent
      },
      {
        path:'ubicaciones',
        component:PedidoComponent
      }
    ]
  }
 
];

@NgModule({
  imports: [ RouterModule.forRoot(routes , {useHash:true})  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
