import { Component, OnInit } from '@angular/core';
import { PedidosService } from './services/pedidos.service';
import { ToastrService } from "ngx-toastr";
import copy from "copy-text-to-clipboard";
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {
  pedidos:any[] = []
  detallePedido:any[] = []
  pedidoSeleccionado:any = {}
  mostrarDetallePedido: boolean = false;
  totalPedido = 0

  constructor(
    private pedidosServices:PedidosService,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    await this.consultarPedidos()
  }

  async consultarPedidos(){
    this.totalPedido = 0
    this.pedidos = await this.pedidosServices.getPedidos()
    this.mostrarDetallePedido = false
    this.pedidoSeleccionado = {}

  }


  async verDetallePedido(pPedido){
    this.totalPedido = 0
    this.mostrarDetallePedido = false
    this.pedidos.forEach(element => {element.activo = false});
    pPedido.activo =true
    this.pedidoSeleccionado = pPedido
    this.detallePedido =  await this.pedidosServices.getDetallePedidoGenerado(pPedido.id_pedido)
    if (this.detallePedido.length > 0) {
      this.detallePedido.forEach((element:any) => {this.totalPedido += element.total })
      this.mostrarDetallePedido = true
    }
  }

  copiar(pCodigo){
    copy(pCodigo);
    this.toastr.success("Copiado " + pCodigo, "");
  }

  facturarProducto(producto){
    producto.producto_facturado = producto.producto_facturado == 1 ? 0 : 1;
  }

  async facturarPedido(){
    let user_sesion = JSON.parse(sessionStorage.getItem(environment.user_storage))
    let body ={
      id_pedido: this.pedidoSeleccionado.id_pedido,
      id_usuario: user_sesion.id_usuario,
      productos:this.detallePedido
    }
    if (await this.pedidosServices.procFacturaPedido(body) ) {
      await this.consultarPedidos()
    } 
  }

  async anularPedido(){
    let user_sesion = JSON.parse(sessionStorage.getItem(environment.user_storage))
    let body ={
      id_pedido: this.pedidoSeleccionado.id_pedido,
      id_usuario: user_sesion.id_usuario
    }
    if (await this.pedidosServices.anularPedido(body) ) {
      await this.consultarPedidos()
    } 
  }
}
