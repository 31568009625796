import { Component, OnInit } from '@angular/core';
import { PedidosService } from '../pedidos/services/pedidos.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {
  pedidos:any[] = []
  constructor(private pedidosServices: PedidosService) { }

  async ngOnInit() {
    await this.getPedidos()
  }

 async getPedidos(){
   this.pedidos = await this.pedidosServices.getPedidosTracking()
  }

}
