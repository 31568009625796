import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService) { }



  async getPedidos(){
    let user = JSON.parse(sessionStorage.getItem(environment.user_storage))
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}pedidos/getPedidosGenerados ` 
    let body 
   let respuesta = []
   
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     console.log(error);
     
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }
 
  async getDetallePedidoGenerado(pPedidoId){
    let user = JSON.parse(sessionStorage.getItem(environment.user_storage))
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}pedidos/getDetallePedidoGenerado ` 
    let body = {id_pedido: pPedidoId}
   let respuesta = []
   
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     console.log(error);
     
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }

  async procFacturaPedido(body){
    let user = JSON.parse(sessionStorage.getItem(environment.user_storage))
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}entregas/procFacturaPedido ` 
     
   let respuesta = false
   
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
     if (datos.estado== true) {
       Swal.fire('Pedido facturado' , 'Se facturó el pedido, se generó la tarea a entregas' , 'success')
       respuesta = true
     }
     
   })
   .catch((error:any)=>{
     console.log(error);
     
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }
  
  async anularPedido(body){
    let user = JSON.parse(sessionStorage.getItem(environment.user_storage))
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}pedidos/anularPedido ` 
     
   let respuesta = false
   
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
     if (datos.estado== true) {
       Swal.fire('Pedido anulado' , 'El pedido se marcó como cerrado.' , 'success')
       respuesta = true
     }
     
   })
   .catch((error:any)=>{
     console.log(error);
     
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }

  async getPedidosTracking(){
    let user = JSON.parse(sessionStorage.getItem(environment.user_storage))
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}pedidos/getPedidosTracking` 
    let body 
   let respuesta = []
   
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     console.log(error);
     
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }
 
 
  async getProductosEmpacar(p_fecha , ruta){ 
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}pedidos/getProductosEmpacar` 
    let body ={fecha:p_fecha , ruta:ruta}
   let respuesta = []
   
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     console.log(error);
     
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }


  async getProductosByRepartidor(body){ 
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}entregas/getDetalleProductoByRepartidor` 
   let respuesta = []
   
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     console.log(error);
     
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }

  async getDateServer(){ 
 
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}entregas/getDateServer` 
   let respuesta 
   let body = {}
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
 
   })
   .catch((error:any)=>{
     console.log(error); 
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }


  async getDateServerNow(){ 
 
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}entregas/getDateServerNow` 
   let respuesta 
   let body = {}
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
 
   })
   .catch((error:any)=>{
     console.log(error); 
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }



  async getRutas(){ 
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}entregas/getRutas` 
   let respuesta 
   let body = {}
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
 
   })
   .catch((error:any)=>{
     console.log(error); 
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }


  async getVentasByDate(p_fecha:any){ 
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}pedidos/getVentasByDate` 
   let respuesta 
   let body = {
     fecha:p_fecha
   }
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
   })
   .catch((error:any)=>{
    this.spinner.hide()
     console.log(error); 
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }
  async getEstadisticasVentasByDate(p_fecha:any){ 
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}pedidos/getEstadisticasVentasByDate` 
   let respuesta 
   let body = {
     fecha:p_fecha
   }
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
   })
   .catch((error:any)=>{
    this.spinner.hide()
     console.log(error); 
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }


  async getVentaDetalle(p_fecha:any, p_vendedor:string){ 
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}pedidos/getVentaDetalle` 
   let respuesta 
   let body = {
     fecha:p_fecha,
     vendedor:p_vendedor
   }
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
   })
   .catch((error:any)=>{
    this.spinner.hide()
     console.log(error); 
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }
  
async getPedidosVentas(p_fecha:any, vendedor){ 
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}pedidos/getPedidosVentas` 
   let respuesta 
   let body = {
     fecha:p_fecha ,
     vendedor
   }
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
   })
   .catch((error:any)=>{
    this.spinner.hide()
     console.log(error); 
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }

}
