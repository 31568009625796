import { Component, OnInit } from '@angular/core';
import { PedidosService } from '../../pedidos/services/pedidos.service';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css']
})
export class PedidoComponent implements OnInit {
  title: string = ''; 
  zoom: number = 12;
  lat= 15.550004912212435; 
  lng= -88.00788700277349;
  ubicaciones = []
  fecha_actual:any
  vendedor_seleccionado:any = {}
  ventas_vendedor:any = {monto:0}
  rutas = []
  constructor(
    private pedidosServices:PedidosService

  ) { }
  
  async ngOnInit() {
    this.fecha_actual = await this.formatDate()
    await this.getRutas()
  }
  async getRutas(){
    this.rutas = await this.pedidosServices.getRutas() 
    
  }

  async formatDate() {
    let dateServer = await this.pedidosServices.getDateServerNow()
    console.log('dateServer' , dateServer[0].fecha);
     var d = new Date(dateServer[0].fecha),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();
 
     if (month.length < 2) 
         month = '0' + month;
     if (day.length < 2) 
         day = '0' + day;
 
     return [year, month, day].join('-');
 }




  async getPedidosUbicaciones(){
    this.ventas_vendedor.monto  = 0
    this.ubicaciones = await this.pedidosServices.getPedidosVentas(this.fecha_actual, this.vendedor_seleccionado)
    this.ubicaciones.forEach(element => {
      this.ventas_vendedor.monto  += element.monto
    });
  }

}
