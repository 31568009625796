import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable, ɵSWITCH_TEMPLATE_REF_FACTORY__POST_R3__ } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';
import { TIPO_USUARIO } from '../../../models/tipoUsuario';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  async login(datosLogin){
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}core/login ` 
    let body = datosLogin
   let respuesta = false 
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     console.log(datos);
     
     this.spinner.hide()
     if (datos.length > 0) {
       if (datos[0].activo == 1) {

         if (datos[0].id_permiso == TIPO_USUARIO.ADMINISTRADOR) {
           respuesta = true
           sessionStorage.setItem(environment.user_storage , JSON.stringify(datos[0]))
         }else{
           Swal.fire('Aviso' , datos[0].mensaje , 'warning' )
         }

       }else{
         Swal.fire('Aviso' , datos[0].mensaje , 'warning' )
        }

      }else{
        Swal.fire('Usuario no encontrado' ,'Usuario o Contraseña incorreccta' , 'error' )
     }
   })
   .catch((error:any)=>{
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }
}
