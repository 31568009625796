import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../services/core/core.service';
import { PedidosService } from '../pedidos/services/pedidos.service';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {
  fecha_actual:any
  ventas:Array<any> = [];
  mostrar_ventas: boolean = false ;
  venta_total = 0
  detalle:Array<any> = []
  mostrar_ventas_detalle: boolean;
  venta_seleccionada = {}
  ventas_totales: any = {};
  constructor(
    private pedidosServices:PedidosService,
    private coreServices:CoreService
  ) { }

  async ngOnInit() {
    this.fecha_actual = await this.formatDate()
    await this.consultarDatos()
  }
  async formatDate() {
    let dateServer = await this.pedidosServices.getDateServerNow()
    console.log('dateServer' , dateServer[0].fecha);
     var d = new Date(dateServer[0].fecha),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();
 
     if (month.length < 2) 
         month = '0' + month;
     if (day.length < 2) 
         day = '0' + day;
 
     return [year, month, day].join('-');
 }

 async consultarDatos(){
   this.mostrar_ventas = false 
   this.mostrar_ventas_detalle = false 
   this.venta_total = 0 
   this.ventas_totales = await this.pedidosServices.getEstadisticasVentasByDate(this.fecha_actual)
   this.ventas = await this.pedidosServices.getVentasByDate(this.fecha_actual)
   if (this.ventas.length > 0) {
     this.mostrar_ventas = true
   }
   this.ventas.forEach((venta)=>{
     this.venta_total += venta.monto_venta
   })
 }

 async verDetalleVenta(venta){
  this.venta_seleccionada = venta
    this.mostrar_ventas_detalle = false 
    this.detalle = await this.pedidosServices.getVentaDetalle(this.fecha_actual ,  venta.vendedor)
     this.mostrar_ventas_detalle = true 
 }

async exportToExcel(data){
  this.coreServices.exportExcel(data ,'pedidos_de_hoy' )
 }
}
