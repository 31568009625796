import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { AsignacionesService } from './services/asignaciones.service';

@Component({
  selector: 'app-asignaciones',
  templateUrl: './asignaciones.component.html',
  styleUrls: ['./asignaciones.component.css']
})
export class AsignacionesComponent implements OnInit {
@ViewChild('modalAsignacion') modalAsignacion:ModalDirective

vendedores = [] 
clientes = []
arregloClientes = []

modelVendedor
modelCliente
  vendedores_asignaciones: any[] = [];
  asignaciones_vendedor: any[] = [];
  mostrarListaAsignaciones = false
  constructor(
    private asignacionesService:AsignacionesService
  ) { }

  async ngOnInit() {
    await this.getVendedoresAsignaciones()
  }

  async contruirControl(){ 
    this.arregloClientes = []
    this.modelCliente = undefined
    this.modelVendedor = undefined
    this.vendedores = await this.asignacionesService.getVendedores()
    this.clientes = await  this.asignacionesService.getClientes()
    this.modalAsignacion.show()
  }

  agregarCliente(){
    if (this.modelCliente != undefined) {
      let dataCliente = this.clientes[this.clientes.findIndex(cliente => cliente.codigo == this.modelCliente)]  
      if (this.arregloClientes.findIndex(cliente => cliente.codigo == this.modelCliente) < 0 ) {
        this.arregloClientes.push(dataCliente)
      }
    }
  }

  quitar(indice:number){
    this.arregloClientes.splice(indice, 1)
  }

  async generarRequisicion(){
    if (this.modelVendedor ==undefined) {
      Swal.fire('Datos incompletos', 'Seleccione un vendedor' , 'warning')
      return
    }
    let data = {
      vendedor:this.modelVendedor,
      clientes:this.arregloClientes
    }
    if (await this.asignacionesService.crearAsignacion(data)  == true) {
      this.modalAsignacion.hide()
      await this.getVendedoresAsignaciones()
      Swal.fire('Proceso exitoso' , 'Se generaron las asignaciones para el vendedor' , 'success')
    }
  }


  async getVendedoresAsignaciones(){
    this.vendedores_asignaciones =  await this.asignacionesService.getVendoresAsignaciones()
  }
  async getAsignacionesPorVendedor(pVendedor){
    this.vendedores_asignaciones.forEach(element => element.activo = false);
    pVendedor.activo = true
    this.asignaciones_vendedor = await this.asignacionesService.getAsignacionesVendedor(pVendedor.codigo_vendedor)
    this.mostrarListaAsignaciones = true
  }
}
