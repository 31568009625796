import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { LoginService } from './services/login.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  usuario = ''
  contrasenia = ''
  constructor(
    private spinner: NgxSpinnerService,
    private loginService:LoginService,
    private router:Router
  ) { }

  ngOnInit() {
  }

  async iniciarSession(){
    if (this.usuario !='' && this.contrasenia !='') {
      let datos ={
        usuario: this.usuario,
        contrasenia: this.contrasenia
      }
      if (await this.loginService.login(datos) ==true) {
        this.router.navigate(['/home'])
      }  
    }
  }

}
