import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Home',
    url: '/home',
    icon: 'icon-home',
    divider:true
  },

  // {
  //   name: 'Asignaciones',
  //   url: '/home/asignaciones',
  //   icon: 'icon-note',
  // },
  {
    name: 'Pedidos',
    url: '/home/pedidos',
    icon: 'icon-bag',
  },
  {
    name: 'Empaque',
    url: '/home/empaque',
    icon: 'icon-social-dropbox',
  },
  {
    name: 'Estado Pedidos',
    url: '/home/entregas',
    icon: 'icon-graph',
  },
  {
    name: 'Usuarios',
    url: '/usuarios',
    icon: 'icon-user',
  },
  {
    name: 'GPS realtime',
    url: '/home/maps',
    icon: 'icon-location-pin',
  },
  {
    name: 'Clientes',
    url: '/home/clientes',
    icon: 'icon-user-following',
  },
  {
    name: 'Reportes',
    icon: 'icon-chart',
    children:[{
      name: 'Ventas',
      url: '/home/reporte',
      icon: 'icon-chart',
    },
    {
    name: 'Inventario',
    url: '/home/inventario',
    icon: 'icon-chart',
    },
    {
    name: 'Ubicaciones',
    url: '/home/ubicaciones',
    icon: 'icon-chart',
    }
  
  ]
  }
];
