import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../services/core/core.service';
import { InventarioService } from '../services/inventario.service';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css']
})
export class InventarioComponent implements OnInit {
  subgrupos: any;
  grupoSeleccionado: any

  constructor(private inventarioServices:InventarioService,
    private coreServices:CoreService
    ) { }
  inventario = []
  
  async ngOnInit() {
    await this.getSubgrupos()
  }

  

  async getSubgrupos(){
    this.subgrupos = await this.inventarioServices.getSubgrupos()
  }
  async getInventario(){
    this.inventario = await this.inventarioServices.getInventario(this.grupoSeleccionado)
  }

  async exportToExcel(){
    this.coreServices.exportExcel(this.inventario, 'reporte_inventario_productos')
  }

}
