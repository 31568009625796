import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AsignacionesService {

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService) { }



  async crearAsignacion(datosCrear){
    let user = JSON.parse(sessionStorage.getItem(environment.user_storage))
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}asignacion/createAsignacion ` 
    let body = datosCrear
        body.usuario = user.id_usuario
   let respuesta = false 
   
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     console.log(error);
     
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }

  async getVendedores(){
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}core/getVendedores ` 
    let body 
   let respuesta = []
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos 
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }


  async getClientes(){
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}core/getClientes ` 
    let body 
   let respuesta = []
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos 
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }

  async getVendoresAsignaciones(){
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}asignacion/getVendoresAsignaciones ` 
    let body 
   let respuesta = []
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos 
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }
 
  async getAsignacionesVendedor(pVendedorId:number){
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}asignacion/getAsignacionesVendedor ` 
    let body ={
      vendedor:pVendedorId
    }
   let respuesta = []
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos 
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  }
}
