import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService) { }



  async getClientesUbicaciones(){
    let user = JSON.parse(sessionStorage.getItem(environment.user_storage))
    this.spinner.show()
    const headers = new HttpHeaders(environment.headers)
    const url =`${environment.url_service}core/getClientesUbicaciones ` 
    let body 
   let respuesta = []
   
   console.log(JSON.stringify(body));
   
   await this.http.post(url , body , {headers}).toPromise()
   .then((datos:any)=>{
     respuesta = datos
     console.log(datos);
     this.spinner.hide()
     
     
   })
   .catch((error:any)=>{
     console.log(error);
     
     this.spinner.hide()
     Swal.fire('Servicio no disponible' , error , 'error')
   })
   return respuesta
  } 
}
