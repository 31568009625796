export const environment = {
  production: false,
  // url_service:'http://200.115.191.114:3000/api/',
  url_service:'http://192.168.1.10:3000/api/',
  // url_service:'http://200.115.190.26:3000/api/',
  headers:{
    'Authorization': '3NTQyODQyNzM2MGE4NWI0MDFjNmMzYjZhMDE5NzgyY2Q',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Accept': 'application/json, text/plain'
  },
  user_storage:'USER_DATA',
   firebaseConfig : {
    apiKey: "AIzaSyDAQC3eRhQDle1xqz8S3GfUnmmYAFqF7Ks",
    authDomain: "appse-cc327.firebaseapp.com",
    databaseURL: "https://appse-cc327-default-rtdb.firebaseio.com",
    projectId: "appse-cc327",
    storageBucket: "appse-cc327.appspot.com",
    messagingSenderId: "239222871757",
    appId: "1:239222871757:web:234ec10a5f51cc3ce13b84",
    measurementId: "G-LNYFE4HCKY"
  },
  mapa_key:'AIzaSyCIsvkkifVHoNuh-Hf7ghx_v2SPy7kmP70'
};
