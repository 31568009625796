import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class InventarioService {

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
    ) { }


async getInventario( grupoSeleccionado ){ 
  this.spinner.show()
  const headers = new HttpHeaders(environment.headers)
  const url =`${environment.url_service}core/getInventario` 
 let respuesta 
 let body = {grupoSeleccionado}
 await this.http.post(url , body , {headers}).toPromise()
 .then((datos:any)=>{
   respuesta = datos
   console.log(datos);
   this.spinner.hide()
 })
 .catch((error:any)=>{
  this.spinner.hide()
   console.log(error); 
   Swal.fire('Servicio no disponible' , error , 'error')
 })
 return respuesta
}

async getSubgrupos( ){ 
  this.spinner.show()
  const headers = new HttpHeaders(environment.headers)
  const url =`${environment.url_service}core/getSubgrupos` 
 let respuesta 
 let body = {}
 await this.http.post(url , body , {headers}).toPromise()
 .then((datos:any)=>{
   respuesta = datos
   console.log(datos);
   this.spinner.hide()
 })
 .catch((error:any)=>{
  this.spinner.hide()
   console.log(error); 
   Swal.fire('Servicio no disponible' , error , 'error')
 })
 return respuesta
}
}
