import { Component, OnInit } from '@angular/core';
import { EntregaService } from './services/entregas.service';
import copy from "copy-text-to-clipboard";
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { PedidosService } from '../pedidos/services/pedidos.service';
import {TreeNode} from 'primeng/api';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-entregas',
  templateUrl: './entregas.component.html',
  styleUrls: ['./entregas.component.css']
})
export class EntregasComponent implements OnInit {

  pedidos:any[] = []
  detallePedido:any[] = []
  pedidoSeleccionado:any = {}
  mostrarDetallePedido: boolean = false;
  totalPedido = 0
  repartidores:any[] = []
  repartidoreSeleccionado = 0
  repartidoreSeleccionadoReporte = 0
  listadoProductos: any[] = []
  fecha_actual:any
  rutas:Array<any> = [] 
  rutaSeleccionada : any = ''
  fecha_actual_reporte = ''

  archivos:TreeNode[] 
 
  constructor( private entregasServices:EntregaService, private toastr: ToastrService ,     private pedidosServices:PedidosService) { }

  async ngOnInit() {
    this.fecha_actual = await this.formatDate()
    await this.getRutas()
    // await  this.llenar_tabla()
    // await this.getPedidos()
    await this.getRepartidores()
 
  }

  async getRutas(){
    this.rutas = await this.pedidosServices.getRutas()
    console.log('rutas' , this.rutas);
    
  }



 async formatDate() {
   let dateServer = await this.pedidosServices.getDateServer()
   console.log('dateServer' , dateServer[0].fecha);
    var d = new Date(dateServer[0].fecha),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
  async getRepartidores(){
    this.repartidores = await this.entregasServices.getRepartidores()
  }
  async getPedidos(){
    this.totalPedido = 0
    this.mostrarDetallePedido = false
    // this.pedidos = await this.entregasServices.getPedidos(this.fecha_actual)
    await this.getProductosEmpacar()
  }
  async verDetallePedido(pPedido){
    console.log(pPedido);
    
    this.totalPedido = 0
    this.mostrarDetallePedido = false
    this.pedidos.forEach(element => {element.activo = false});
    pPedido.activo =true
    this.pedidoSeleccionado = pPedido
    this.repartidoreSeleccionado =pPedido.id_repartidor
    this.detallePedido =  await this.entregasServices.getDetallePedidoGenerado(pPedido.id_pedido)
    if (this.detallePedido.length > 0) {
      this.detallePedido.forEach((element:any) => {this.totalPedido += element.total })
      this.mostrarDetallePedido = true
    }
  }

  empacarProducto(producto){
    producto.producto_empacado = producto.producto_empacado == 1 ? 0 : 1;
  }
  copiar(pCodigo){
      copy(pCodigo);
      this.toastr.success("Copiado " + pCodigo, "");
    }
    
    async empacarPedido(){
      let user = JSON.parse(sessionStorage.getItem(environment.user_storage))
      let body = {
        id_usuario:user.id_usuario,
        id_pedido : this.pedidoSeleccionado.id_pedido,
        id_repartidor:this.repartidoreSeleccionado,
        productos:this.detallePedido
      } 

      if (await this.entregasServices.procEmpacarPedido(body)) {
        await this.getPedidos() 
        await this.verDetallePedido(this.pedidoSeleccionado)
      }
      
    }

    async getProductosEmpacar(){
      this.listadoProductos = await this.pedidosServices.getProductosEmpacar(this.fecha_actual , this.rutaSeleccionada)
    }
    
    

    //  async llenar_tabla(){
      //    this.archivos = await  this.entregasServices.getFilesystem() 
      
      //  }
      
      imprimir(){
        this.entregasServices.generatePDF(this.listadoProductos , this.rutaSeleccionada)
      }
      
      async imprimirDatosDelPedido(){
        await this.verDetallePedido(this.pedidoSeleccionado)
        let productos = this.detallePedido.filter(det => det.producto_empacado == 1)
        this.entregasServices.generatePDF_Pedido(productos , this.pedidoSeleccionado)
        console.log(this.pedidoSeleccionado);
      }
      
      async verReporteByRepartidor(){
        console.log(this.fecha_actual , this.repartidoreSeleccionadoReporte);
        let body={
          fecha:this.fecha_actual,
          id_repartidor: this.repartidoreSeleccionadoReporte
        }
        let listadoProductos = await this.pedidosServices.getProductosByRepartidor(body)
        let nombre = this.repartidores[  this.repartidores.findIndex(re => re.id_repartidor==this.repartidoreSeleccionadoReporte) ]
        let encabezado = {
          fecha:this.fecha_actual,
          repartidor: nombre.nombre
        }
        this.entregasServices.generatePDFByRepartidor(listadoProductos , encabezado)
        
      }


    async consultarDatosDeRuta(){
      console.log(this.rutaSeleccionada );
      if (this.rutaSeleccionada !="" ) {
        this.pedidos = await this.entregasServices.getPedidos(this.fecha_actual , this.rutaSeleccionada) 
        await this.getProductosEmpacar()
      }else{
        Swal.fire("Falta datos" , "Selecciones una ruta", "warning")
      }
    }

    async asignarPedidosRepartidor(){
      if (this.repartidoreSeleccionado != 0) {
        await this.entregasServices.asignarPedidosARepartidor(this.pedidos , this.repartidoreSeleccionado)
        
      }
    }
    
    
    async imprimirLiquidacionRepartidor(){
      let nombre = this.repartidores[  this.repartidores.findIndex(re => re.id_repartidor==this.repartidoreSeleccionadoReporte) ]
        let encabezado = {
          fecha:this.fecha_actual,
          repartidor: nombre.nombre
        }

      let productos = await this.entregasServices.getProductosLiquidacionRepartidor( this.fecha_actual_reporte , this.repartidoreSeleccionadoReporte)
 
      await this.entregasServices.generarReporteLiquidacion(productos , encabezado)
      
    }

    }
    