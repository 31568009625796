import { Component, OnInit } from '@angular/core';
import { ClientesService } from './services/clientes.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {
  title: string = ''; 
  zoom: number = 15;
  lat= 15.550004912212435; 
  lng= -88.00788700277349;
  clientesUbicaciones: any[] = []
  constructor(private clientesServices:ClientesService) { }

 async ngOnInit() {
    await this.obtenerUbicaciones()
  }

  async obtenerUbicaciones() {
    this.clientesUbicaciones =  await this.clientesServices.getClientesUbicaciones()
    console.log(this.clientesUbicaciones);
    

  }

}
